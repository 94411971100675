.editor-iframe {
  border-radius: 10px;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.16);
  border: none;
}

.masonry-grid {
  display: flex;
  width: 100%;
  margin: 0 0 60px 0;
  justify-content: center;
}

.masonry-grid_column {
  padding: 0;
}

.masonry-grid_column > div {
  margin: 10px;
}

.delete-icon {
  position: absolute;
  display: none;
}

.delete-icon:hover {
  background-color: #dddddd;
}

div[class*='gallery-image-container-']:hover > .delete-icon {
  z-index: 99;
  display: flex;
  cursor: pointer;
}

.gallery-image {
  opacity: 0.9;
}

div[class*='gallery-image-container-']:hover > .gallery-image {
  opacity: 1;
}
