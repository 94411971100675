:root {
  --main-font-family: Montserrat, sans-serif;
}

.MuiInputBase-input,
.MuiFormLabel-root,
.MuiFormHelperText-root,
.MuiInputBase-input,
.MuiOutlinedInput-input,
.MuiInputBase-root,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-shrink,
.MuiInputLabel-outlined,
.MuiFormLabel-filled {
  font-family: var(--main-font-family);
  font-size: 14px;
}

.MuiInputBase-root {
  margin: 0 0 20px 0;
  width: 100%;
  font-family: var(--main-font-family);
}
