.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar,
.virtualized-table-styledBox::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar-thumb,
.virtualized-table-styledBox::-webkit-scrollbar-thumb {
  background: var(--brandMain);
  border-radius: 10px;
}
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar-track,
.virtualized-table-styledBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  display: none;
}

.ReactVirtualized__Table__headerRow {
  background-color: var(--brandMain);
  padding: 16px 0;
  padding-right: 0 !important;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.ReactVirtualized__Table__row:hover {
  background: rgba(0, 0, 0, 0.07);
}
.ReactVirtualized__Table__row:focus,
.ReactVirtualized__Table__headerColumn:focus,
.ReactVirtualized__Grid:focus,
.ReactVirtualized__Table:focus {
  outline: none;
}
.ReactVirtualized__Table__row {
  border-bottom: 1px solid lightgray;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
}

.ReactVirtualized__Table__rowColumn:first-child {
  display: flex;
  justify-content: center;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  box-sizing: border-box;
  padding: 10px;
  min-width: 0px;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
}

.ReactVirtualized__Table__rowColumn {
  border-right: 1px solid lightgray;
  height: 100%;
  display: flex;
  align-items: center;
}

.ReactVirtualized__Table .ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}
